<template>
  <div class="priceAlert">
    <headbox title="价格提醒" bgColor="#fff" :isbackperson="true" isborder="false"></headbox>
    <van-pull-refresh v-model="refreshing" style="min-height: 100vh;"  @refresh="onRefresh">
      <template v-if="!isHaveData">
        <van-empty
          class="custom-image"
          :image="require('@/assets/images/pic_no information@2x.png')"
          description="暂无数据!"
        />
      </template>
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        @load="onLoad"
        v-if="isHaveData"
      >
        <div class="priceAlertList" v-for="(item,index) in priceAlertList" :key="index">
          <div class="priceAlertItem">
            <div class="flex-between">
              <div class="priceAlertItemLeft">
                <div class="priceAlertItemLeftTitle">
                  价格设置: {{ item.price }}元/克
                </div>
                <div>您设置的<span style="color: #FF2D2E">{{item.key === 'bojin9996' ? '铂金' : item.key === 'bajin9996' ? '钯金' : item.key === 'baiyin9999' ? '白银' : '黄金'}}{{item.type === 'HUIGOU' ? '回购' : '销售'}}</span>目标价为{{ item.price }}元/克</div>
                <div>{{ item.created | formatDate_ }}</div>
              </div>
              <div class="priceAlertItemRight" :style="{color: item.effect ? '#FF2D2E': '#999999', background: item.effect ? '#FAE7E2': '#EFEFEF'}">
                {{ item.effect ? "待提醒" : "已失效" }}
              </div>
            </div>
          </div>
        </div>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import headbox from "@/components/head";
import * as api from "@/utils/api";

export default {
  components: {
    headbox
  },
  data() {
    return {
      // 判断是否有数据
      isHaveData: true,
      priceAlertList: [], // 价格提醒列表数据
      loading: false,
      finished: false,
      refreshing: false,
      page: 0,
      size: 10
    };
  },
  mounted() {
    // this.onLoad();
  },
  methods: {
    async onRefresh() {
      this.finished = false;
      //请求最新的数据
      const res = await api.getPriceAlertList({
        page: 0,
        size: this.size
      });
      this.page = res.number + 1;
      this.priceAlertList = res.content;

      this.isHaveData = this.priceAlertList.length !== 0;
      this.refreshing = false;
      this.loading = false;
      if (res.last === true) {
        this.finished = true;
      }
    },
    onLoad() {
      setTimeout(async () => {
        try {
          const res = await api.getPriceAlertList({
            page: this.page,
            size: this.size
          });
          // 如果加载完数据了 last为true
          // 把上一段 number赋值给page
          this.page = res.number + 1;
          // 把数组里的每一项一一铺开添加
          this.priceAlertList.push(...res.content);
          this.isHaveData = this.priceAlertList.length !== 0;
          // 本次加载完毕，就应该把loading改成false，代表本次加载完了，让加载中状态消失
          // 这样才可以继续下次加载
          this.loading = false;
          if (res.last === true) {
            this.finished = true;
          }
        } catch {
          this.isHaveData = false;
        }
      }, 1000);
    }
  }
};
</script>

<style lang="scss" scoped="true">
.priceAlert {
  background-color: #f5f5f5;
  height: 100%;
}
.priceAlertList {
  margin: 20px 20px 20px 30px;
  .priceAlertItem {
    background-color: #ffffff;
    padding: 10px;
    margin-bottom: 20px;
    .priceAlertItemLeft {
      margin-left: 10px;
      color: #666666;
      font-size: 24px;
      line-height: 46px;
      .priceAlertItemLeftTitle {
        color: #333333;
        font-weight: 600;
        font-size: 30px;
      }
    }
    .priceAlertItemRight {
      margin-right: 20px;
      border: 1px solid;
      width: 120px;
      height: 50px;
      text-align: center;
      line-height: 50px;
      font-size: 28px;
      border-radius: 18px;
    }
  }
}
</style>