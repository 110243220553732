var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "priceAlert" },
    [
      _c("headbox", {
        attrs: {
          title: "价格提醒",
          bgColor: "#fff",
          isbackperson: true,
          isborder: "false"
        }
      }),
      _c(
        "van-pull-refresh",
        {
          staticStyle: { "min-height": "100vh" },
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing"
          }
        },
        [
          !_vm.isHaveData
            ? [
                _c("van-empty", {
                  staticClass: "custom-image",
                  attrs: {
                    image: require("@/assets/images/pic_no information@2x.png"),
                    description: "暂无数据!"
                  }
                })
              ]
            : _vm._e(),
          _vm.isHaveData
            ? _c(
                "van-list",
                {
                  attrs: {
                    finished: _vm.finished,
                    "finished-text": "没有更多了"
                  },
                  on: { load: _vm.onLoad },
                  model: {
                    value: _vm.loading,
                    callback: function($$v) {
                      _vm.loading = $$v
                    },
                    expression: "loading"
                  }
                },
                _vm._l(_vm.priceAlertList, function(item, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "priceAlertList" },
                    [
                      _c("div", { staticClass: "priceAlertItem" }, [
                        _c("div", { staticClass: "flex-between" }, [
                          _c("div", { staticClass: "priceAlertItemLeft" }, [
                            _c(
                              "div",
                              { staticClass: "priceAlertItemLeftTitle" },
                              [
                                _vm._v(
                                  " 价格设置: " + _vm._s(item.price) + "元/克 "
                                )
                              ]
                            ),
                            _c("div", [
                              _vm._v("您设置的"),
                              _c(
                                "span",
                                { staticStyle: { color: "#FF2D2E" } },
                                [
                                  _vm._v(
                                    _vm._s(
                                      item.key === "bojin9996"
                                        ? "铂金"
                                        : item.key === "bajin9996"
                                        ? "钯金"
                                        : item.key === "baiyin9999"
                                        ? "白银"
                                        : "黄金"
                                    ) +
                                      _vm._s(
                                        item.type === "HUIGOU" ? "回购" : "销售"
                                      )
                                  )
                                ]
                              ),
                              _vm._v("目标价为" + _vm._s(item.price) + "元/克")
                            ]),
                            _c("div", [
                              _vm._v(
                                _vm._s(_vm._f("formatDate_")(item.created))
                              )
                            ])
                          ]),
                          _c(
                            "div",
                            {
                              staticClass: "priceAlertItemRight",
                              style: {
                                color: item.effect ? "#FF2D2E" : "#999999",
                                background: item.effect ? "#FAE7E2" : "#EFEFEF"
                              }
                            },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(item.effect ? "待提醒" : "已失效") +
                                  " "
                              )
                            ]
                          )
                        ])
                      ])
                    ]
                  )
                }),
                0
              )
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }